import { Button, makeStyles, Typography } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { createElement, VFC } from "react";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { GenericPopoverConfig, QuestStepProps } from "./quests.types";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: 300,
      backgroundColor: theme.palette.primary.main,
    },
    textAndBtns: {},
    textContent: {
      padding: theme.spacing(1.5, 2, 2, 2),
      color: theme.palette.getContrastText(theme.palette.primary.main),
    },
    btnCont: {
      padding: theme.spacing(0, 0, 1, 0.5),
    },
    btn: {
      color: theme.palette.common.black,
    },
    svgCont: {},
  }),
  {
    classNamePrefix: "QuestGenericPopover",
  }
);

export type QuestGenericPopoverJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestGenericPopoverProps = QuestStepProps &
  GenericPopoverConfig & {
    classes?: Partial<ClassNameMap<QuestGenericPopoverJSSClassKey>>;
    className?: string;
    onClose: () => void;
  };

export const QuestGenericPopover: VFC<QuestGenericPopoverProps> = ({
  className,
  classes: extClasses,
  popoverConfig,
  onClose,
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const handleClose = useCallbackSafeRef(() => {
    onClose?.();
  });

  return (
    <div className={clsx(classes.root, className)}>
      {!!popoverConfig?.svgBackground && (
        <div className={classes.svgCont}>{createElement(popoverConfig.svgBackground)}</div>
      )}
      <div className={classes.textAndBtns}>
        <Typography variant="body1" className={classes.textContent}>
          {popoverConfig?.textContent}
        </Typography>
        <div className={classes.btnCont}>
          <Button className={classes.btn} onClick={handleClose}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};
